/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useContext, useState } from "react"
import FloatingSelect from "../../../components/Elements/Form/FloatingSelect"
import FloatingInput from "../../../components/Elements/Form/FloatingInput"
import FloatingCheckbox from "../../../components/Elements/Form/FloatingCheckbox"
import AsyncFloatingSelect from "../../../components/Elements/Form/AsyncFloatingSelect"
import MyUserSegmentationContext from "../../../context/MyUserSegmentation/MyUserSegmentationContext"
import { Table, Tr, Td } from "../../../components/Table/TableElements"
import Skeleton from "../../../components/Skeleton"
import CAProvinces from "../../../utils/canadian-provinces.json"
import Button from "../../../components/Elements/Button"
import { searchRetailUsers } from "../../../helpers/DrupalHelper"
import StateContext from "../../../context/state/StateContext"

const RetailUsers = props => {

  const { token } = useContext(StateContext).state

  const {
    segments,
    data,
    getUsers,
    create,
    editData,
    setEditData,
    editing,
    segment,
    filters,
    setFilters,
  } = useContext(MyUserSegmentationContext)
  const [showSelectedOnly, setShowSelectedOnly] = useState(false)

  const initColumns = [
    "User Email",
    "User First Name",
    "User Last Name",
    "Job Title",
    // "Regions",
    // "User Type",
  ]

  useEffect(async () => {
    console.log(editData, 'editDataR')

    if (editData && editData?.field_group_retail_users?.length > 0 && retailUsers.length === 0) {

      const getData = await searchRetailUsers(token, {
        ids: editData.field_group_retail_users.map(i => i.target_id)
      })

      setRetailUsers(getData)
    }
  }, [editData])

  useEffect(() => {
    setRetailUsers([])
  }, [segment])

  const [columns, setColumns] = useState(initColumns)

  useEffect(() => {
    if (segment !== null) {
      setColumns([
        // <FloatingCheckbox
        //   flush
        //   name="select_all"
        //   value={null}
        //   hookForm={false}
        //   disabled={!editing}
        //   checked={
        //     segments.filter(i => parseInt(i.id) === parseInt(segment.value))[0]
        //       .field_group_users.length === data.users.length
        //   }
        //   onChange={e => {
        //     if (e.target.checked) {
        //       setEditData({
        //         ...editData,
        //         field_group_users: data.users.map(i =>
        //           Object.assign({ target_id: parseInt(i.id) })
        //         ),
        //       })
        //     } else {
        //       setEditData({
        //         ...editData,
        //         field_group_users: [],
        //       })
        //     }
        //   }}
        // />,
        ...initColumns,
      ])
    } else if (create === true) {
      setColumns([
        // <FloatingCheckbox
        //   flush
        //   name="select_all"
        //   value={null}
        //   hookForm={false}
        //   disabled={!editing}
        //   checked={
        //     "field_group_users" in editData
        //       ? editData?.field_group_users?.length === data.users.length
        //       : false
        //   }
        //   onChange={e => {
        //     if (e.target.checked) {
        //       setEditData({
        //         ...editData,
        //         field_group_users: data.users.map(i =>
        //           Object.assign({ target_id: parseInt(i.id) })
        //         ),
        //       })
        //     } else {
        //       setEditData({
        //         ...editData,
        //         field_group_users: [],
        //       })
        //     }
        //   }}
        // />,
        ...initColumns,
      ])
    } else {
      setColumns(initColumns)
    }
  }, [segment, editing])

  console.log(
    data.users
      .map(i =>
        i.regions && i.regions?.includes(",") ? i.regions.split(",") : i.regions
      )
      .filter(f => f !== "" && f !== null)
      .filter((v, i, a) => a.indexOf(v) === i)
      .map(i => {
        return {
          value: i,
          label: i,
        }
      }),
    "x"
  )

  const [retailUsers, setRetailUsers] = useState([])

  useEffect(() => {
    setEditData({
      ...editData,
      field_group_retail_users: retailUsers.map(i => {
        console.log(i, 'iiiiheee')
        return Object.assign({ target_id: parseInt(i.uid) })
      }),
    })
    // if (e.target.checked) {
    //   const existingUsers =
    //     "field_group_users" in editData
    //       ? editData.field_group_users
    //       : []
    //   setEditData({
    //     ...editData,
    //     field_group_users: [
    //       ...existingUsers,
    //       {
    //         target_id: parseInt(item.id),
    //       },
    //     ],
    //   })
    // }
    // if (!e.target.checked) {
    //   setEditData({
    //     ...editData,
    //     field_group_users:
    //       editData.field_group_users.filter(
    //         i =>
    //           parseInt(i.target_id) !== parseInt(item.id)
    //       ),
    //   })
    // }
  }, [retailUsers])

  return (
    <>
      {(editing === true || create === true) &&
        <div className="border border-[#EBEBEB] rounded px-4 py-4 mb-8">
          <div
            className="flex max-w-[1400px] space-x-4 items-center"
            css={css`
            &&& {
              > * {
                margin-bottom: 0 !important;
              }
            }
          `}
          >
            <AsyncFloatingSelect
              // provisionTempState
              exposeAllData
              label="Search Users"
              retailUsersAsOptions
              onChange={(e) => {

              }}
              customOnChange={e => {
                console.log(e, 'eeee')
                setRetailUsers((prev) => {
                  return [...prev, e]
                })
              }}
              name="Search"
              // isMulti={true}
              flush
            />
          </div>
        </div>
      }

      {(() => {
        if (!create && !editing && !segment) {
          return <>Create or select a segment above to assign retail users.<div className="mb-20"></div></>
        } else if ((create || editing) && retailUsers.length === 0) {

          return <>You haven't added any users yet. Search above to find some.<div className="mb-20"></div></>
        } else if (retailUsers.length) {
          return <Table fit columns={columns}>
            {retailUsers
              .map((item, k) => {
                return (
                  <Tr key={k}>
                    <Td>{item.email}</Td>
                    <Td>{item.first_name}</Td>
                    <Td>{item.last_name}</Td>
                    <Td>{item.job_title}</Td>
                    <Td><Button
                      onClick={() => {
                        console.log('clicked')
                      }}
                      className="w-full"
                      type="button"
                      variant="primary"
                    >
                      Remove
                    </Button></Td>
                  </Tr>
                )
              })}
          </Table>
        } else {
          return <Skeleton table />
        }
      })()}

      {/* {segment && editing && (

      )} */}

      {/* {
        retailUsers.length === 0 ? (
          <>
            You haven't added any users yet.
          </>
        ) : (
          <Table fit columns={columns}>
            {retailUsers
              .map((item, k) => {
                return (
                  <Tr key={k}>
                    <Td>{item.email}</Td>
                    <Td>{item.first_name}</Td>
                    <Td>{item.last_name}</Td>
                    <Td>{item.job_title}</Td>
                    <Td><Button
                      onClick={() => {
                        console.log('clicked')
                      }}
                      className="w-full"
                      type="button"
                      variant="primary"
                    >
                      Remove
                    </Button></Td>
                  </Tr>
                )
              })}
          </Table>
        )
      } */}
    </>
  )
}
export default RetailUsers
